/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from "react";
import { graphql, PageProps } from "gatsby";
import { Query } from "../../generated/graphql-types";
import { Hero, Statement } from "../../components";
// eslint-disable-next-line import/named
import { buildMenu, Layout } from "../../layouts";
import { GA_CTA } from "../../components/GA_CTA";
import { GA_personcarousel } from "../../components/GA_personcarousel";
import colleen from "../../images/colleen.jpg";
import damian from "../../images/damian.jpg";
import socialHead2 from "../../images/bestversion-1.png";
// import socialHead2 from "../../images/damian.jpg";

// const component: React.FC<PageProps<Pick<Query, "graphCmsSectorPage">>> = ({
//   path,
//   data: { graphCmsSectorPage: sectorPage },
// }) => {
//   console.log(sectorPage);
//   if (!sectorPage) return null;
//   return (
//     <Layout
//       pagePath={path}
//       menu={buildMenu(sectorPage.menu)}
//       footerMenu={buildMenu(sectorPage.footerMenu)}
//       keywords={sectorPage.keywords}
//       title={sectorPage.pageTitle || sectorPage.sector}
//       description={sectorPage.description}
//     >
//       <Hero
//         title="Partners in Performance Energy Transition Insights"
//         video={getVideo(sectorPage.heroVideo)}
//         image={getImage(sectorPage.heroImage)}
//         short={true}
//         treatment={sectorPage.heroTreatment}
//         showSearch={true}
//       />
//       <Statement
//         title=""
//         headline="A collection of analysis, research and stories about our capabilities from our Energy Transition Experts."
//         id={""}
//         className="custom-class"
//       />
//       <ResearchComponent name="" />
//     </Layout>
//   );
// };

// export default component;

// export const sectorPageQuery = graphql`
//   query sectorPageQueryAndSectorPageQuery($id: String) {
//     graphCmsSectorPage(id: { eq: $id }) {
//       sectorType
//       pageTitle
//       keywords
//       description
//       menu {
//         ...MenuFragment
//       }
//       businessChallengesIntroduction
//       businessChallenges {
//         headline
//         id
//         content {
//           cleaned
//         }
//       }
//       heroImage {
//         ...ImageFragment
//       }
//       statement {
//         cleaned
//       }
//       heroVideo {
//         ...VideoFragment
//       }
//       heroTreatment
//       id
//       keyStatistic
//       leadership {
//         ...ProfileFragment
//       }
//       leadershipTitle
//       quote
//       quoteFrom {
//         ...ProfileFragment
//       }
//       infographicIntroduction {
//         cleaned
//       }
//       infographicDetails {
//         cleaned
//       }
//       genericInfographicText {
//         cleaned
//       }
//       sector
//       slug
//       statisticDetails
//       storiesTitle
//       storiesImage {
//         ...ImageFragment
//       }
//       successes {
//         __typename
//         ... on GraphCMS_CaseStudy {
//           ...CaseStudyReferenceFragment
//         }
//         ... on GraphCMS_Story {
//           ...StoryReferenceFragment
//         }
//         ... on GraphCMS_Insight {
//           ...InsightReferenceFragment
//         }
//       }
//       form {
//         ...FormFragment
//       }
//     }
//   }
// `;

const Image = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 1280px) 1280px, 100vw",
      src: { socialHead2 },
      srcSet: socialHead2,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 1280,
  height: 720,
};

const ResearchDocument: React.FC<
  PageProps<Pick<Query, "allGraphCmsResearchDocument" | "graphCmsSectorPage">>
> = ({
  path,
  data: {
    allGraphCmsResearchDocument: { nodes },
    graphCmsSectorPage: sectorPage,
  },
}) => {
  if (!ResearchDocument) return null;
  console.log("return diversity");
  return (
    <Layout
      pagePath={path}
      menu={buildMenu(sectorPage?.menu)}
      footerMenu={buildMenu(sectorPage?.footerMenu)}
      keywords={sectorPage?.keywords}
      title="Diversity | Partners in Performance | Global Management Consultancy"
      description={sectorPage?.description}
    >
      <Hero
        title="Be the best version of you"
        video=""
        image={Image}
        short={true}
        treatment={sectorPage?.heroTreatment}
        showSearch={true}
      />
      <Statement
        title="We believe unleashing our individual potential starts when people feel empowered to bring their entire selves to work."
        headline=""
        cleanedMessage={[
          {
            type: "paragraph",
            children: [
              {
                text: "We need to feel safe and supported to speak up, share our ideas and spark courageous conversations that help bring out the best in all of us. We know that our diverse backgrounds, experiences and perspectives help us to see things differently to the person next to us. As an organisation, we need that diversity of viewpoints and innovative thinking from our people to achieve the game-changing results we aim for when partnering with clients.",
              },
            ],
          },
          {
            type: "paragraph",
            children: [
              {
                text: "Our simple belief is that we achieve better outcomes when people feel safe to be themselves. That is why we work hard to create a working environment in which all our people can belong, contribute and thrive. As a firm, we are committed to building an inclusive, diverse, flexible, and accessible culture in which all our people can unleash their full potential.",
              },
            ],
          },
        ]}
        id={""}
        className=""
      />
      <GA_personcarousel
        id={""}
        content={[
          {
            id: "1",
            Name: "Colleen Grady",
            Position: "Partner",
            area: " Australia and New Zealand",
            link: colleen,
            id2: "2",
            Name2: "Damian Lahoud",
            Position2: " Senior Associate",
            area2: "Africa",
            link2: damian,
          },
          {
            id: "2",
            Name: "Colleen Grady",
            Position: "Partner",
            area: " Australia and New Zealand",
            link: colleen,
            id2: "2",
            Name2: "Damian Lahoud",
            Position2: " Senior Associate",
            area2: "Africa",
            link2: damian,
          },
          {
            id: "3",
            Name: "Colleen Grady",
            Position: "Partner",
            area: " Australia and New Zealand",
            link: colleen,
            id2: "2",
            Name2: "Damian Lahoud",
            Position2: " Senior Associate",
            area2: "Africa",
            link2: damian,
          },
          // {
          //   id: "4",
          //   Name: "Colleen Grady",
          //   Position: "Partner",
          //   area: " Australia and New Zealand",
          //   link: colleen,
          //   id2: "2",
          //   Name2: "Damian Lahoud",
          //   Position2: " Senior Associate",
          //   area2: "Africa",
          //   link2: damian,
          // },
          // {
          //   id: "5",
          //   Name: "Colleen Grady",
          //   Position: "Partner",
          //   area: " Australia and New Zealand",
          //   link: colleen,
          //   id2: "2",
          //   Name2: "Damian Lahoud",
          //   Position2: " Senior Associate",
          //   area2: "Africa",
          //   link2: damian,
          // },
          // {
          //   id: "6",
          //   Name: "Alyssa",
          //   Position: "Manager",
          //   area: " Australia and New Zealand ",
          //   link: colleen,
          //   id2: "2",
          //   Name2: "Damian Lahoud",
          //   Position2: " Senior Associate",
          //   area2: "Africa",
          //   link2: damian,
          // },
        ]}
      />
      <GA_CTA
        id={""}
        title1="Climate Action"
        title2="Corporate Social Responsibility "
        Link1="/en/climate-action"
        Link2="/en/corporate-social-responsibility"
      />
    </Layout>
  );
};

export default ResearchDocument;

export const IndexQuery = graphql`
  {
    allGraphCmsResearchDocument {
      nodes {
        id
        documentName
        documentThumbnail {
          url
        }
        documentDescription
        documents {
          url
        }
      }
    }
    graphCmsSectorPage {
      sectorType
      pageTitle
      keywords
      description
      menu {
        ...MenuFragment
      }
      businessChallengesIntroduction
      businessChallenges {
        headline
        id
        content {
          cleaned
        }
      }
      heroImage {
        ...ImageFragment
      }
      statement {
        cleaned
      }
      heroVideo {
        ...VideoFragment
      }
      heroTreatment
      id
      keyStatistic
      leadership {
        ...ProfileFragment
      }
      leadershipTitle
      quote
      quoteFrom {
        ...ProfileFragment
      }
      infographicIntroduction {
        cleaned
      }
      infographicDetails {
        cleaned
      }
      genericInfographicText {
        cleaned
      }
      sector
      slug
      statisticDetails
      storiesTitle
      storiesImage {
        ...ImageFragment
      }
      successes {
        __typename
        ... on GraphCMS_CaseStudy {
          ...CaseStudyReferenceFragment
        }
        ... on GraphCMS_Story {
          ...StoryReferenceFragment
        }
        ... on GraphCMS_Insight {
          ...InsightReferenceFragment
        }
      }
      form {
        ...FormFragment
      }
    }
  }
`;
